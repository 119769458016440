


class InvitePage {

	constructor(props) {



	}

	ready = () => {

		var pathName = window.location.pathname;
		console.log('Invite PAGE READY', pathName);

		this.identify();


		if (pathName.toLowerCase().startsWith("/invite/confirm")) { this.confirm(); }
		if (pathName.toLowerCase().startsWith("/invite/unsubscribe")) { this.unsubscribe(); }
	}

	identify = () => {

		const email = this.getEmail();

		if (email != null) {

			if (typeof sendinblue !== 'undefined') {

				sendinblue.identify(email);
			}

		}

	}

	confirmEmail = (email) => {

		if (typeof sendinblue === 'undefined') {
			console.log(email + ' would now be a confirmed subscriber');
			return;
		}

		sendinblue.track("InviteConfirmed");

		console.log(email + ' is now a confirmed subscriber');
	}

	unsubscribeEmail = (email) => {

		if (typeof sendinblue === 'undefined') {
			console.log(email + ' would now be unsubscribed');
			return;
		}

		sendinblue.track("InviteUnsubscribed");

		console.log(email + ' is now unsubscribed');
	}

	confirm = () => {

		const email = this.getEmail();

		if (email != null) {

			this.confirmEmail(email);
		}
	}

	unsubscribe = () => {

		const email = this.getEmail();

		if (email != null) {
			this.unsubscribeEmail(email);
		}
	}

	getEmail = () => {
		return ((window.pageData) && (typeof window.pageData.email === 'string')) ? window.pageData.email : null;
	}


}

export default InvitePage;