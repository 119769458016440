


class HomePage {

	constructor(props) {

		this.currentFeatures$ = null;


		this.slideCaptions = {
			0: 'Operations',
			1: 'Communication',
			2: 'Issue tracking',
			3: 'Brand reviews',
			4: 'Site comparisons',
		}

		this.mainCaptions = {
			0: 'OPERATIONS',
			1: 'STORES',
			2: 'RESTAURANTS',
			3: 'PUBS',
			4: 'COFFEE SHOPS',
			5: 'HOTELS',
			6: 'BARS'



		}


		this.featurePoints = {
			0: '#operations',
			1: '#communication',
			2: '#issues',
			3: '#reviews',
			4: '#overview',
		}

		this.startSlide = 0;

		this.typewriter = null;
		/*
		<img width="384px" src="images/shots/tasks.png" alt="Tasks">
		<img width="384px" src="images/shots/feed.png" alt="Feed">
		<img width="384px" src="images/shots/issues.png" alt="Issues">
		<img width="384px" src="images/shots/review.png" alt="Review">
		<img width="384px" src="images/shots/overview.png" alt="Overview">
		*/

	}

	ready = () => {

		console.log('HOME PAFE READY');


		$('.landing-caption > .title').html('REDUCE THE STRESS OF MANAGING YOUR');
		//this.currentFeatures$ = $(this.featurePoints[this.startSlide]);
		//this.currentFeatures$.removeClass('is-hidden');

		this.typewriter = new Typewriter('#main-heading', { loop: true, cursor:'' });

		var timing = 3000;


		this.typewriter
		.typeString(this.mainCaptions[0])
    .pauseFor(timing * 3)
		.deleteAll()
		.typeString(this.mainCaptions[1])
		.pauseFor(timing)
		.deleteAll()
		.typeString(this.mainCaptions[2])
		.pauseFor(timing)
		.deleteAll()
		.typeString(this.mainCaptions[3])
		.pauseFor(timing)
		.deleteAll()
		.typeString(this.mainCaptions[4])
		.pauseFor(timing)
		.deleteAll()
		.typeString(this.mainCaptions[5])
		.pauseFor(timing)
		.deleteAll()
		.typeString(this.mainCaptions[6])
		.pauseFor(timing)
		.start();



		$('.screenshots').slick({


			autoplaySpeed: 6000,
			autoplay:true,
			draggable: false,
			arrows: true,
			dots: false,
			//appendDots:$('.dots'),
			fade: true,
			//speed: 900,
			touchMove: false,
			infinite: true,
			cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
			touchThreshold: 100
		});

		// On before slide change
		$('.screenshots').on('beforeChange', (event, slick, currentSlide, nextSlide) => {


		});

		$('.nextArrow').on('click', () => {
			$('.screenshots').slick("slickNext");
		});

		$('.prevArrow').on('click', () => {
			$('.screenshots').slick("slickPrev");
		});


		/*







		// On before slide change
		$('.screenshots').on('beforeChange', (event, slick, currentSlide, nextSlide) => {



			//console.log(nextSlide);
			console.log('beforeChange ' + currentSlide + " => " + nextSlide);

			if (this.currentFeatures$ != null) {
				this.currentFeatures$.addClass('is-hidden');

			}

			this.currentFeatures$ = $(this.featurePoints[nextSlide]);

			console.log(this.currentFeatures$);

			this.currentFeatures$.removeClass('is-hidden');

			this.typewriter.deleteAll().typeString(this.slideCaptions[nextSlide]).start();


		});
		$('.screenshots').on('afterChange', (event, slick, currentSlide) => {



			//console.log(nextSlide);
			console.log('afterChange ' + currentSlide);
			//console.log(this.slideCaptions[nextSlide]);

			//this.typewriter.typeString(this.slideCaptions[nextSlide]).start();
		});

		*/


	}



}

export default HomePage;