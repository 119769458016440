
import InviteRequestor from './app/invites/invite-requestor';
import DefaultPage from './app/pages/default-page';
import HomePage from './app/pages/home-page';
import InvitePage from './app/pages/invite-page';

$(document).ready(function () {

	console.log('DOCUMENT ready');
	console.log('path', window.location.pathname);

	window.app = { instances: {}, classes: { InviteRequestor: InviteRequestor, HomePage: HomePage, DefaultPage: DefaultPage, InvitePage: InvitePage } };

	var pageInstanceName = null;
	var pathName = window.location.pathname;
	window.app.lastTop = 0;

	if (pathName.toLowerCase().startsWith("/invite")) { pathName = '/invite'; }

	switch(pathName) {
		case '/':
			pageInstanceName = 'HomePage';
			break;

		case '/invite':
			pageInstanceName = 'InvitePage';
			break;

		default:
			pageInstanceName = 'DefaultPage';

			// code block
	}

	console.log('WINDOW LOAD');



	var pageInstance = new window.app.classes[pageInstanceName];
	window.app.instances[pageInstanceName] = pageInstance

	pageInstance.ready();








	/*
	infinite: true,
		useTransform: true,

    autoplaySpeed: 4500,
    arrows:false,
    slidesToShow:1,
    slidesToScroll:1,
    dots:false,
    cssEase: 'ease-out',
    responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: false
      }
		}]
	*/

	//Preloader
	//$(window).on('load', function () { // makes sure the whole site is loaded



			$('#status').fadeOut(); // will first fade out the loading animation
			$('#preloader').delay(350).fadeOut('slow'); // will fade out the white DIV that covers the website.
			$('body').delay(350).css({ 'overflow': 'visible' });
	//})

	//Mobile menu toggle
	if ($('.navbar-burger').length) {
			$('.navbar-burger').on("click", function () {

					var menu_id = $(this).attr('data-target');
					$(this).toggleClass('is-active');
					$("#" + menu_id).toggleClass('is-active');
					$('.navbar.is-light').toggleClass('is-dark-mobile')
			});
	}

	//Animate left hamburger icon and open sidebar
	$('.menu-icon-trigger').click(function (e) {
			e.preventDefault();
			$('.menu-icon-wrapper').toggleClass('open');
			$('.sidebar').toggleClass('is-active');
	});

	//Close sidebar
	$('.sidebar-close').click(function () {
			$('.sidebar').removeClass('is-active');
			$('.menu-icon-wrapper').removeClass('open');
	})

	//Sidebar menu
	if ($('.sidebar').length) {
			$(".sidebar-menu > li.have-children > a").on("click", function (i) {
					i.preventDefault();
					if (!$(this).parent().hasClass("active")) {
							$(".sidebar-menu li ul").slideUp();
							$(this).next().slideToggle();
							$(".sidebar-menu li").removeClass("active");
							$(this).parent().addClass("active");
					}
					else {
							$(this).next().slideToggle();
							$(".sidebar-menu li").removeClass("active");
					}
			});
	}

	//Navbar Clone
	if ($('#navbar-clone').length) {
			$(window).scroll(function () {    // this will work when your window scrolled.
					var top = $(window).scrollTop();  //getting the scrolling height of window


					if (top > 50) {
						$("#navbar-clone").addClass('is-active');
					} else {
						$("#navbar-clone").removeClass('is-active');
					}
			});
	}

	//Init feather icons
	feather.replace();

	//reveal elements on scroll so animations trigger the right way
	var $window = $(window),
			win_height_padded = $window.height() * 1.1;


	//var isTouch = Modernizr ? Modernizr.touch : false;

	$window.on('scroll', revealOnScroll);

	function revealOnScroll() {
			var scrolled = $window.scrollTop();
			$(".revealOnScroll:not(.animated)").each(function () {
					var $this = $(this),
							offsetTop = $this.offset().top;

					if (scrolled + win_height_padded > offsetTop) {
							if ($this.data('timeout')) {
									window.setTimeout(function () {
											$this.addClass('animated ' + $this.data('animation'));
									}, parseInt($this.data('timeout'), 10));
							} else {
									$this.addClass('animated ' + $this.data('animation'));
							}
					}
			});
	}

	/*
	// Back to Top button behaviour
	var pxShow = 600;
	var scrollSpeed = 500;
	$(window).scroll(function () {
			if ($(window).scrollTop() >= pxShow) {
					$("#backtotop").addClass('visible');
			} else {
					$("#backtotop").removeClass('visible');
			}
	});

	$('#backtotop a').on('click', function () {
			$('html, body').animate({
					scrollTop: 0
			}, scrollSpeed);
			return false;
	});
	*/

	//modals
	$('.modal-trigger').on('click', function(){
			var modalID = $(this).attr('data-modal');

			var el$ = $('#' + modalID);
			el$.addClass('is-active');

			var modalClass = el$.attr('data-class')

			if (modalClass) {
				var i = new window.app.classes[modalClass];
				window.app.instances[modalClass] = i;
				i.open(el$);
			}



	})
	$('.modal-close, .close-modal').on('click', function(){


		var el$ = $(this).closest('.modal');

		var modalClass = el$.attr('data-class')

		if (modalClass) {
			var i =  window.app.instances[modalClass];
			if (i.close()) {

				window.app.instances[modalClass] = null;

			}

		}

		el$.removeClass('is-active');
	})

	// Select all links with hashes
	$('a[href*="#"]')
			// Remove links that don't actually link to anything
			.not('[href="#"]')
			.not('[href="#0"]')
			.click(function (event) {
					// On-page links
					if (
							location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
							&&
							location.hostname == this.hostname
					) {
							// Figure out element to scroll to
							var target = $(this.hash);
							target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
							// Does a scroll target exist?
							if (target.length) {
									// Only prevent default if animation is actually gonna happen
									event.preventDefault();
									$('html, body').animate({
											scrollTop: target.offset().top
									}, 550, function () {
											// Callback after animation
											// Must change focus!
											var $target = $(target);
											$target.focus();
											if ($target.is(":focus")) { // Checking if the target was focused
													return false;
											} else {
													$target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
													$target.focus(); // Set focus again
											};
									});
							}
					}
			});
})
