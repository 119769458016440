


class DefaultPage {

	constructor(props) {


	}

	ready = () => {

		console.log('DEFAULT PAFE READY');
	}



}

export default DefaultPage;