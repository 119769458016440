


class InviteRequestor {

	constructor(props) {

		this.closing = false;
		this.el$ = null;
		this.submitBtn$ = null;
		this.firstName$ = null;
		this.email$ = null;
		this.closeBtn$ = null;
		this.rqeuestContent$ = null;
		this.feedbackContent$ = null;
		this.successText$ = null;
		this.okBtn$ = null;
	}

	open = (el$) => {

		this.setup(el$);

		this.rqeuestContent$.removeClass('is-hidden');
		this.feedbackContent$.addClass('is-hidden');
		this.firstName$.val('');
		this.email$.val('');
		this.successText$.val('');
		this.clearError();

		this.firstName$.focus();
	}

	validateEmail(email) {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}


	isValidEmail = (emailInput) => {

		return this.validateEmail(emailInput);
	}

	isValidName = (nameInput) => {

		return (nameInput != null) && (nameInput.trim() !== '');
	}

	buildForm = () => {

		const email = this.email$.val().trim();
		const firstName = this.firstName$.val().trim();
		const validEmail = this.isValidEmail(email);
		const validName = this.isValidName(firstName);
		let errorMsg = '';
		let control$ = null;

		if (!validEmail) {
			errorMsg = "Hmm.. we having trouble sending to that email.<br/> Please double check it.";
			control$ = this.email$;
		}

		if (!validName) {
			errorMsg = "Please enter your first name. <br/> We could say 'Here you go buddy', but we prefer 'Here you go Bob'.";
			control$ = this.firstName$;
		}

		return {
			firstName,
			email,
			isValid: validEmail && validName,
			control$: control$,
			error: errorMsg
		};
	}

	onSuccessfullySent = (msg) => {


		this.rqeuestContent$.addClass('is-hidden');
		this.feedbackContent$.removeClass('is-hidden');

		this.successText$.html(`We've sent an email to <b>${msg.email}</b>. Please check your email.`);

	}

	onFailedToSend = (e) => {

		console.log('onFailedToSend', e);
		this.showError("Hmmm. We are unable to send an invite at this time.<br/> We're looking into it! Please try later.");
	}

	submitInvite = (inviteForm) => {

		this.submitBtn$.addClass('is-loading');
		this.closeBtn$.addClass('is-hidden');

		const msg = { messageType: 'REQUEST_INVITE', firstName: inviteForm.firstName, email: inviteForm.email };

		$.post( '/api/send', msg )
 			.done(() => {

				this.submitBtn$.removeClass('is-loading');
				this.closeBtn$.removeClass('is-hidden');
				this.onSuccessfullySent(msg);
			})
			.fail((e) => {

				this.submitBtn$.removeClass('is-loading');
				this.closeBtn$.removeClass('is-hidden');
				this.onFailedToSend(e);
			});
	}

	recordInviteRequested = (inviteForm) => {

		try {

			if (typeof sendinblue !== 'undefined') {

				sendinblue.identify(inviteForm.email, { 'FIRSTNAME': inviteForm.firstName });
			}

			if (typeof ym !== 'undefined') {
				ym(56601958, 'reachGoal', 'InviteRequested');
			}
			if (typeof gtag !== 'undefined') {
				gtag('event', 'InviteRequested');
			}



		}
		catch(e) {console.error(e);}
	}

	_onSubmitClick = () => {

		var inviteForm = this.buildForm();

		console.log('inviteForm', inviteForm);

		if (inviteForm.isValid) {

			this.clearError();

			this.recordInviteRequested(inviteForm);


			this.submitInvite(inviteForm);

		} else {


			this.showError(inviteForm.error);

			if (inviteForm.control$) {
				inviteForm.control$.focus().select();
			}
		}

	}

	_onOkayClick = () => {

		this.close();
	}

	setup = (el$) => {

		this.el$ = el$;

		this.okBtn$ = this.el$.find('.ok-btn');
		this.okBtn$.on('click', this._onOkayClick);
		this.firstName$ = this.el$.find('input[name ="firstName"]');
		this.email$ = this.el$.find('input[name ="email"]');
		this.rqeuestContent$ = this.el$.find('.request-content');
		this.feedbackContent$ = this.el$.find('.feedback-content');
		this.closeBtn$ = this.el$.find('.modal-close');
		this.successText$ = this.el$.find('.success-text');
		this.submitBtn$ = this.el$.find('.send-invite-btn');
		this.submitBtn$.on('click', this._onSubmitClick);
	}

	clearError = () => {

		const error$ = this.el$.find('.error');
		error$.addClass('is-hidden');
		error$.html('');
	}

	showError = (error) => {

		const error$ = this.el$.find('.error');

		console.log('error$', error$);

		error$.removeClass('is-hidden');

		error$.html(error);
	}

	teardown = () => {

		this.firstName$ = null;
		this.email$ = null;
		this.submitBtn$.off('click');
		this.submitBtn$ = null;
		this.closeBtn$ = null;
		this.rqeuestContent$ = null;
		this.feedbackContent$ = null;
		this.successText$ = null;
		this.okBtn$.off('click');
		this.okBtn$ = null;
		this.el$ = null;
	}

	close = () => {

		if (!this.closing) {
			this.closing = true;

			setTimeout(() => {
				this.el$.find('.modal-close').click()
			}, 100 );
			return false;
		} else {

			this.teardown();
			return true;
		}
	}

}

export default InviteRequestor;